import React from "react";

function MailIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      fill="none"
      viewBox="0 0 14 12"
    >
      <path
        fill="#7DF9FF"
        d="M13.727 4.223c.109-.082.273 0 .273.136v5.579c0 .738-.602 1.312-1.313 1.312H1.313A1.296 1.296 0 010 9.937V4.36c0-.136.137-.218.246-.136.629.492 1.422 1.093 4.211 3.117.574.41 1.559 1.312 2.543 1.312.957 0 1.969-.902 2.516-1.312 2.789-2.024 3.582-2.625 4.21-3.117zM7 7.75c-.656.027-1.559-.793-2.023-1.121C1.34 4.004 1.067 3.758.247 3.102.081 2.992 0 2.8 0 2.582v-.52C0 1.353.574.75 1.313.75h11.374C13.399.75 14 1.352 14 2.063v.519c0 .219-.11.41-.273.52-.82.656-1.094.902-4.73 3.527C8.53 6.957 7.628 7.777 7 7.75z"
      ></path>
    </svg>
  );
}

export default MailIcon;
