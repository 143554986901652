import React from "react";

function BurnIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="23"
      height="23"
      fill="none"
      viewBox="0 0 23 23"
    >
      <path
        fill="#fff"
        d="M11.522 2.493l-.471.404S9.369 4.27 7.704 6.267c-1.665 1.996-3.391 4.63-3.391 7.39 0 1.987.814 3.643 2.133 4.76 1.238 1.048 2.895 1.615 4.695 1.685.12.006.238.023.359.023.12 0 .239-.017.36-.023 1.8-.07 3.456-.637 4.694-1.684 1.32-1.118 2.134-2.775 2.134-4.762 0-2.485-1.736-5.074-3.392-7.143-1.656-2.068-3.301-3.594-3.301-3.594l-.473-.426zm-.044 1.999c.374.354 1.354 1.241 2.696 2.92 1.577 1.974 3.076 4.49 3.076 6.244 0 1.606-.623 2.825-1.64 3.684a5.115 5.115 0 01-.808.561c.177-.424.292-.884.292-1.37 0-4.492-2.404-7.233-2.404-7.233L11.388 7.84l.044 1.954s.003.915-.112 1.797c-.059.44-.14.879-.247 1.123-.03.07-.045.078-.068.112a1.588 1.588 0 01-.516-.36c-.241-.246-.404-.494-.404-.494l-.697-1.078-.539 1.168s-.943 1.924-.943 4.47c0 .486.115.946.292 1.37a5.182 5.182 0 01-.809-.561c-1.016-.86-1.639-2.078-1.639-3.684 0-2.125 1.508-4.585 3.077-6.469 1.331-1.595 2.274-2.375 2.65-2.695h.001zm1.213 7.57a10.57 10.57 0 01.965 4.47 2.156 2.156 0 11-4.312 0 9.87 9.87 0 01.427-2.808c.322.289.718.583 1.302.583.343 0 .668-.156.876-.36.209-.201.326-.44.427-.673.163-.38.247-.8.315-1.213z"
      ></path>
    </svg>
  );
}

export default BurnIcon;
