import React from "react";

function VaultsIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill="#fff"
        d="M15.935 6.06h-7.87a2 2 0 00-2 2v6a1.993 1.993 0 002 2h7.87a2 2 0 002-2v-6a2.006 2.006 0 00-2-2zm1 8a1 1 0 01-1 1h-7.87a.99.99 0 01-1-1v-6a1 1 0 011-1h7.87a1 1 0 011 1v6z"
      ></path>
      <path
        fill="#fff"
        d="M18.435 3.06H5.565a2.507 2.507 0 00-2.5 2.5v11a2.5 2.5 0 002.5 2.5v.38a1.5 1.5 0 001.5 1.5h1.43a1.5 1.5 0 001.5-1.5v-.38h4v.38a1.5 1.5 0 001.5 1.5h1.44a1.5 1.5 0 001.5-1.5v-.38a2.5 2.5 0 002.5-2.5v-11a2.507 2.507 0 00-2.5-2.5zm-9.44 16.38a.5.5 0 01-.5.5h-1.43a.5.5 0 01-.5-.5v-.38h2.43v.38zm8.44 0a.5.5 0 01-.5.5H15.5a.508.508 0 01-.5-.5v-.38h2.44l-.005.38zm2.5-2.88a1.5 1.5 0 01-1.5 1.5H5.565a1.5 1.5 0 01-1.5-1.5v-11a1.5 1.5 0 011.5-1.5h12.87a1.5 1.5 0 011.5 1.5v11z"
      ></path>
      <path
        fill="#fff"
        d="M14.265 10.56h-.61A1.656 1.656 0 0012.5 9.4v-.61a.49.49 0 00-.5-.48.5.5 0 00-.5.48v.61a1.656 1.656 0 00-1.16 1.16h-.61a.5.5 0 00-.48.5.491.491 0 00.48.5h.61a1.655 1.655 0 001.16 1.16v.62a.49.49 0 00.5.47.486.486 0 00.5-.47v-.62a1.621 1.621 0 001.16-1.16h.61a.487.487 0 00.48-.5.49.49 0 00-.485-.5zM12 11.81a.75.75 0 110-1.5.75.75 0 010 1.5z"
      ></path>
    </svg>
  );
}

export default VaultsIcon;
