import React from "react";

function UserIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      fill="none"
      viewBox="0 0 13 15"
    >
      <path
        fill="#101010"
        d="M8.982 9.063c2.024 0 3.691 1.667 3.691 3.69v.684c0 .739-.601 1.313-1.312 1.313H1.736a1.296 1.296 0 01-1.313-1.313v-.683c0-2.024 1.641-3.691 3.665-3.691.792 0 1.148.437 2.46.437 1.286 0 1.641-.438 2.434-.438zm2.379 4.374v-.683a2.38 2.38 0 00-2.379-2.379c-.41 0-1.039.438-2.434.438-1.421 0-2.05-.438-2.46-.438-1.313 0-2.352 1.066-2.352 2.379v.684h9.625zM6.548 8.625a3.921 3.921 0 01-3.937-3.938C2.61 2.527 4.36.75 6.548.75c2.16 0 3.938 1.777 3.938 3.938a3.938 3.938 0 01-3.938 3.937zm0-6.563c-1.449 0-2.625 1.204-2.625 2.626a2.626 2.626 0 002.625 2.625c1.422 0 2.625-1.176 2.625-2.625 0-1.422-1.203-2.625-2.625-2.625z"
      ></path>
    </svg>
  );
}

export default UserIcon;
