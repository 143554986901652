import React from "react";

function LinkedInIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      fill="none"
      viewBox="0 0 32 31"
    >
      <path
        fill="#7DF9FF"
        fillRule="evenodd"
        d="M14.064.086c-1.804.246-3.42.743-4.992 1.534-3.917 1.972-6.89 5.654-7.961 9.861-.681 2.678-.681 5.162 0 7.84.259 1.017.547 1.792 1.053 2.834a15.406 15.406 0 0012.264 8.598c.883.093 3.146.041 3.921-.09 3.454-.58 6.48-2.168 8.795-4.612a15.45 15.45 0 004.045-13.213c-.761-4.6-3.626-8.647-7.756-10.956C21.756.944 19.931.351 17.91.086c-.875-.114-3.01-.115-3.846 0zm-3.61 6.578c.481.156.854.479 1.092.946.183.36.207.46.207.873 0 .801-.388 1.42-1.1 1.755-.3.14-.41.158-.998.158-.603 0-.693-.015-1.005-.17-.419-.205-.845-.663-.975-1.047a2.584 2.584 0 01-.096-.683c0-.533.134-.9.465-1.268.432-.483.848-.651 1.619-.658.297-.002.608.035.79.094zm11.459 5.205c1.467.376 2.477 1.436 2.922 3.066l.16.587.02 3.905.019 3.906-1.853-.016-1.853-.016-.03-3.467c-.031-3.468-.031-3.468-.175-3.877-.167-.473-.504-.892-.87-1.081-.173-.09-.397-.137-.73-.155-.649-.034-1.078.124-1.506.554-.387.39-.572.773-.635 1.321-.028.238-.052 1.852-.053 3.585l-.003 3.152-1.85-.016-1.85-.016-.015-5.654-.016-5.653h3.731v.754c0 .417.025.753.056.753.03 0 .113-.093.183-.208.366-.592 1.336-1.262 2.096-1.447.53-.129 1.706-.117 2.252.023zM11.52 17.663V23.3H7.789l-.015-5.654-.015-5.655 1.88.016 1.881.016v5.639z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default LinkedInIcon;
