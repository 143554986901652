import React from "react";

function StakeIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="21"
      fill="none"
      viewBox="0 0 21 21"
    >
      <path
        fill="#fff"
        d="M10.5 1.969c4.416 0 7.875 2.018 7.875 4.593v7.875c0 2.576-3.46 4.594-7.875 4.594-4.416 0-7.875-2.018-7.875-4.593V6.561c0-2.575 3.46-4.593 7.875-4.593zM3.937 10.5c0 .79.647 1.594 1.773 2.208 1.268.692 2.97 1.073 4.79 1.073 1.82 0 3.522-.381 4.79-1.073 1.126-.614 1.773-1.419 1.773-2.208V9.135c-1.4 1.23-3.793 2.021-6.563 2.021-2.77 0-5.163-.794-6.563-2.021V10.5zM15.29 4.354c-1.268-.691-2.97-1.073-4.79-1.073-1.82 0-3.522.382-4.79 1.073-1.126.615-1.772 1.42-1.772 2.208 0 .79.646 1.594 1.772 2.209 1.268.691 2.97 1.073 4.79 1.073 1.82 0 3.522-.382 4.79-1.073 1.126-.615 1.773-1.42 1.773-2.209 0-.789-.647-1.593-1.773-2.208zM5.71 16.646c1.268.691 2.97 1.073 4.79 1.073 1.82 0 3.522-.382 4.79-1.073 1.126-.615 1.773-1.42 1.773-2.209v-1.364c-1.4 1.23-3.793 2.02-6.563 2.02-2.77 0-5.163-.793-6.563-2.02v1.364c0 .79.647 1.594 1.773 2.209z"
      ></path>
    </svg>
  );
}

export default StakeIcon;
