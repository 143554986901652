import React from "react";

function DbIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="39"
      height="39"
      fill="none"
      viewBox="0 0 39 39"
    >
      <path
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2.61"
        d="M37.435 7.968c0 .841-.464 1.674-1.365 2.451-.901.777-2.223 1.483-3.888 2.078-1.665.595-3.643 1.067-5.819 1.389a47.328 47.328 0 01-6.863.487c-4.757 0-9.319-.675-12.682-1.876-3.363-1.201-5.253-2.83-5.253-4.53 0-.84.464-1.673 1.365-2.45.901-.777 2.223-1.484 3.888-2.078 1.665-.595 3.643-1.067 5.819-1.389a47.329 47.329 0 016.863-.487c2.355 0 4.688.165 6.863.487 2.177.322 4.154.794 5.82 1.389 1.664.594 2.986 1.3 3.887 2.078.901.777 1.365 1.61 1.365 2.45zM37.435 31.027c0 1.125-.829 2.23-2.403 3.203-1.574.974-3.838 1.782-6.564 2.345-2.727.562-5.82.858-8.968.858-3.148 0-6.241-.296-8.967-.858-2.727-.563-4.991-1.371-6.565-2.345-1.574-.974-2.403-2.078-2.403-3.203M37.435 23.34c0 1.125-.829 2.23-2.403 3.204-1.574.973-3.838 1.782-6.564 2.344-2.727.562-5.82.858-8.968.858-3.148 0-6.241-.296-8.967-.858-2.727-.562-4.991-1.37-6.565-2.345-1.574-.973-2.403-2.078-2.403-3.202M37.435 15.654c0 1.125-.829 2.23-2.403 3.203-1.574.974-3.838 1.782-6.564 2.345-2.727.562-5.82.858-8.968.858-3.148 0-6.241-.296-8.967-.858-2.727-.563-4.991-1.371-6.565-2.345-1.574-.974-2.403-2.078-2.403-3.203M1.565 7.968v23.06M37.435 7.968v23.06"
      ></path>
    </svg>
  );
}

export default DbIcon;
