import React from "react";

function FacebookIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      fill="none"
      viewBox="0 0 32 31"
    >
      <path
        fill="#7DF9FF"
        fillRule="evenodd"
        d="M14.986.018C11.06.423 7.91 1.886 5.29 4.521 2.866 6.958 1.42 9.903.886 13.484c-.05.343-.08 1.079-.079 1.994.002 1.585.074 2.243.388 3.564 1.355 5.7 5.87 10.179 11.576 11.48.36.082.686.149.728.15.06 0 .075-1.242.075-6.011v-6.012H9.909V14.36h3.648l.036-2.039c.038-2.17.083-2.589.367-3.443a4.932 4.932 0 011.473-2.209c.427-.37 1.382-.839 2.077-1.02.507-.13.643-.139 2.193-.13.909.004 1.882.031 2.163.06l.51.051.016 1.91.016 1.91-1.458.032c-1.605.035-1.87.082-2.31.407-.284.21-.47.55-.559 1.023-.033.177-.06 1.026-.06 1.885v1.563h4.275l-.034.166c-.042.21-.516 3.911-.516 4.033 0 .078-.25.09-1.863.09H18.02V30.8l.286-.04c2.53-.357 5.046-1.413 7.165-3.01 1.164-.876 2.632-2.444 3.473-3.71 1.407-2.113 2.34-4.725 2.513-7.03.022-.297.063-.554.092-.571.028-.018.051-.494.051-1.06 0-.564-.023-1.012-.051-.994-.028.017-.069-.216-.09-.519a14.43 14.43 0 00-.217-1.433c-.611-3.041-2.045-5.733-4.215-7.914-2.37-2.383-5.302-3.86-8.706-4.386-.605-.093-2.81-.17-3.335-.115zM.8 15.448c0 .581.009.819.02.528.011-.29.011-.766 0-1.057-.011-.29-.02-.053-.02.528z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default FacebookIcon;
